import * as React from "react"
// import { Link } from "gatsby"

import GlobalNav from "../components/navigation/GlobalNav"
import Footer from "../components/navigation/Footer"

import Container from 'react-bootstrap/Container'
// import Row from 'react-bootstrap/Row'
// import Col from 'react-bootstrap/Col'

// markup
const NotFoundPage = () => {
  return (
    <main>
        <Container className="home-hero no-gutter">
            <GlobalNav /> 
        </Container>
        <Container className="no-gutter">
            <h1>Oops, Can't Find That Page</h1>
            <p>Sorry, the page you tried to find is not on this website.</p>
        </Container>
       
        <Footer />
    </main>
  )
}

export default NotFoundPage
